import React from "react"

import SEO from "../components/seo"

import PageHeaderSlim from '../components/pageHeaderSlim/pageHeaderSlim'

const NotFoundPage = () => (
  <React.Fragment>
    <SEO title="404: Not found" />
    <PageHeaderSlim title="Page Not Found" />
  </React.Fragment>
)

export default NotFoundPage
